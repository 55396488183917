* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Raleway;
  overflow-x:hidden;
  /* overflow: hidden;  */
  /* Hide scrollbars */
}

.common-button {
  background: #D0004B;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 800px;
  color: #fff;
  border: 0px;
  padding: 10px 30px;
  font-weight: 800;
  font-size: 20px;
}

.common-button-disable {
  background: #A0AEC0;
  /* box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24); */
  border-radius: 800px;
  color: #fff;
  border: 0px;
  padding: 10px 30px;
  font-weight: 800;
  font-size: 20px;
}

.heading-h1 {
  font-weight: 700;
  font-size: 44px;
  color: #1A1A1A;
  text-align: center;
  padding: 60px 0px 20px;
}

/* slider */
.homepage-slider .slick-slide img {
  width: 100% !important;
  padding: 15px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #D0004B;
  border-color: #D0004B;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #D0004B;

}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #7E7E7E;
}

.ant-steps .ant-steps-item-title::after {
  height: 6px;

}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #D0004B;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #D0004B;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #d0004b1c;
}

/* slider-css */
.slick-dots {
  bottom: -34px;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  content: url('https://ik.imagekit.io/rzlzf40okya/elixir/blue_circle.svg');
  text-align: center;
  opacity: .45;
  color: #000;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #000;
  top: -4px;
  left: -4px;
  content: url('https://ik.imagekit.io/zwhvovcr84s/Ellipse_9_aNEUr2wte.svg?updatedAt=1683812185341');
}


@media only screen and (max-width : 767px) {
  .CB-for-mobile {
    font-size: 14px;
  }

  .heading-h1 {
    font-size: 20px;
    padding: 0;
  }

  .homepage-slider .slick-slide img {
    padding: 6px;
  }
}
a{
  text-decoration:none;
}
input[type=text]::placeholder{
  color:#A0AEC0;
  font-weight: 400;
}

input[type=number]::placeholder{
  color:#A0AEC0;
  font-weight: 400;
}
textarea::placeholder {
  color: #A0AEC0;
}
