.banner-grid {
    display: grid;
    grid-template-columns: 45% 55%;
    padding: 85px 0px 0px 131px;
    align-items: center;
    background-image: url("../../public/assets/newBanner.svg");
    height: 670px;
    background-repeat: no-repeat;
    background-size: cover;
}


.d-mobile {
    display: none;
}

.banner-grid-img {
    text-align: end;
}

.banner-grid img {
    width: 100%;
    border-radius: 20px;
    max-width: 624px;
}

.banner-content {
    /* padding: 90px; */
}

.apart-head {
    text-align: center;
    padding: 30px;
}

.feature-sec {
    background: #FAFAFA;
    padding: 2px 0px 215px 0px;
}

.feature-bg img {
    width: 100%;

}

.feature-sec h4 {
    color: #1A1A1A;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.864px; /* 119.318% */
    text-transform: capitalize;
}

.feature-sec p {
    color: #7E7E7E;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.left-track {
    text-align: right;
}

.first-track {
    position: relative;
    margin: 5.5rem 0rem;
}

.secnd-track {
    position: relative;
    top: 7rem;
    left: 5rem;
}

.third-track {
    margin-bottom: 15rem;
}

.fourth-track {
    position: relative;
    left: -11rem;
}

.apart-right {
    margin-bottom: 25px;
    padding-right: 130px;
    display: flex;
}

.apart-opicty {
    opacity: 0.6;
}

.borderLeft {
    border-left: 4px solid #80CBC4;
}

.apart-opicty .borderLeft {
    border-left: 4px solid #232233;
    opacity: 0.1;
}

.apart-box {
    border-radius: 8px;
    padding: 10px 30px;
    background: rgba(211, 237, 234, 0.20);
}

.apart-box h5 {
    color: #1A1A1A;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px; /* 150% */
}

.apart-box p {
    color: #1A1A1A;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.homepage-slider {
    padding-bottom: 90px;
}

.banner-content h2 {
    font-weight: 700;
    font-size: 44px;
}

.banner-content p {
    font-weight: 400;
    font-size: 20px;
    color: #7E7E7E;
    margin: 30px 30px 30px 0;
}

.about-us {
    text-align: center;
    padding: 60px 0px;
    background-image: url("../../public/assets/about-banner.svg");
}

.about-us button {
    min-width: 244px;
    max-width: 244px;
}

.about-us p {
    padding-bottom: 40px;
    color: #7E7E7E;
    line-height: 28px;
}

.about-box p {
    padding-bottom: 0px !important;
    margin-bottom: 0px;
    color: #808080 !important;
    line-height: 24px !important;
}

.about-box {
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 20px;
    background-color: #fff;
    text-align: center;
}

.banner-content button {
    width: 35%;
    min-width: 244px;
}

.img-bg {
    background-color: #D3EDEA;
    border-radius: 8px;
    text-align: center;
    margin: 0 auto;
    padding: 13px;
    width: 100px;
}

.about-grid {
    display: grid;
    grid-template-columns: 31% 31% 31%;
    gap: 40px;

}

.about-box {
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 20px;
    padding: 26px;
}

.about-box h4 {
    font-weight: 600;
    font-size: 19px;
    padding: 30px 0px 16px;
}

.about-box p {
    font-weight: 500;
    font-size: 13px;
}

.contact-box {
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 20px;
    padding: 30px;
    max-width: 499px;
    min-width: 499px;
    /* margin: 0px auto; */

}

.form-box {
    max-width: 360px;
    max-height: 540px;
    padding: 79px 40px;
    border-radius: 0px 16px 16px 0px;
    background-color: #80CBC4;

}

.num {
    width: 89px;
    height: 29px;
    border-radius: 14px;
    background: rgba(255, 255, 255, 0.20);
    color: #fff;
    margin-right: 20px;
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.form-box h4 {
    color: #FFF;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 25px;
}

.form-box p {
    color: #FFF;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.new-contact h2 {
    font-weight: 700;
    font-size: 30px;
    color: #1A1A1A;
    text-align: left;
    padding: 10px 0px 30px;
}

.input-date-box {
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
    height: 47px;
    padding: 0px 10px;
    font-size: 16px;
    width: 100%;
}

.input-box {
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
    height: 54px;
    padding: 0px 10px;
    font-size: 16px;
    width: 100%;
}

.fund-input {
    height: 66px !important;
}

.mobile-news-card {
    display: none;
}

.margin-mosque {
    margin-right: 70px;
}

.new-contact {
    margin-bottom: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .customDatePicker input {
    background-image: url("../components/Icon/formCalendar.svg");
    background-repeat: no-repeat;
    background-position: 95% 50%;
} */

/* .contact-form {
    display: grid;
    grid-template-columns: 50% 45%;
    gap: 50px;
} */

.social-icon {
    margin-bottom: 20px;
}

.social-icon img {
    margin-right: 20px;
}

.location_icon img {
    margin-right: 10px;
}

.location_icon a {
    text-decoration: none;
    color: #1A1A1A;
}

.contact-box button {
    width: 100%;
}

:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}

.contact-form h5 {
    font-weight: 500;
    font-size: 20px;
    color: #1A1A1A;
}

.onboarding-bg {
    padding: 40px 0px;
    /* background: url(../../public/homebg.png),
     linear-gradient(180deg, rgba(211, 237, 234, 0) 0%, 
     rgba(211, 237, 234, 0.5) 22.92%, rgba(211, 237, 234, 0.7) 49.48%, 
     rgba(211, 237, 234, 0.5) 72.92%, rgba(211, 237, 234, 0) 100%); */
    background-position: 100%;

}

.onboarding-bg h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 52px;
    text-align: center;
    color: #1A1A1A;
    padding: 36px 0px 0px 0px;

}

.onboarding-bg p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6C6C72;
    text-align: center;

}

.board-box {
    background: #FFFFFF;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: rgba(26, 26, 26, 0.2);
    /* 6 */
    /* max-height: 563px;
    min-height: 563px; */
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 0px 0px 20px 20px;
    width: 698px;
    margin: 0 auto;
    padding: 50px;
}

.tab-active {
    background: #D0004B;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: rgba(26, 26, 26, 0.2);
    border-radius: 12px 12px 0px 0px;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
}

.information-modal {
    list-style: none;
    text-align: center;
    padding: 0px;
    width: 698px;
    height: 76px;
    margin: 0 auto;

}


.information-modal li {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #D0004B;
    border-radius: 12px 12px 0px 0px;
    border: 1px solid #d7d7d7;
}

.border_bottom {
    border-bottom: 1px solid #D9DBE9;
    padding-top: 50px;
}

.grey-border {
    border-top: 1px solid #D9DBE9;
}

.mosque-form h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #1A1A1A;
    margin: 30px 0px;
}

.mosque-form label {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 6px;
    color: #7E7E7E;
}

.gray-bt {
    background: #A0AEC0;
    border-radius: 800px;
    color: #fff;
    border: 0px;
    font-weight: 800;
    font-size: 18px;
    width: 166px;
    height: 60px;
}

.date_class .ant-picker {
    width: 100% !important;
    height: 66px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
    border-radius: 8px;
    height: 54px;
}

.subscription-form {
    height: 500px;
    padding-right: 13%;
}

.form-height {
    height: 500px;
}

.ant-picker-clear {
    display: none;
}

.ant-select-selection-item {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    color: #1A1A1A;
}

.fill-bt {
    border-radius: 800px;
    color: #fff;
    border: 0px;
    font-weight: 800;
    width: 166px;
    height: 60px;
    background-color: #D0004B !important;
}

.prev-bt {
    font-weight: 800;
    width: 197px;
    height: 60px;
    color: #D0004B;
    border: 2px solid #D0004B;
    border-radius: 800px;
    background-color: #fff;
}

.error-box {
    background: #FFFFFF;
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    width: 698px;
    margin: 0 auto;
}

.error-box p {
    font-weight: 400;
    font-size: 18px;
    color: #FE1717;
    margin-bottom: 0px;
}

.error-box a {
    color: #D0004B;
    font-weight: 600;
    font-size: 18px;
    padding-left: 10px;
    text-decoration: underline;
}

.error-msg {
    color: red;
    font-size: 13px;
}


.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 12px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: #D9DBE9;
    border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #D0004B;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* end */

#progressbar {
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;

}

#progressbar li {
    list-style-type: none;
    width: 32.66%;
    float: left;
    position: relative;
    font-weight: 400
}

#progressbar #step1:before {
    content: "1";

}

#progressbar #step2:before {
    content: "2";

}

#progressbar #step3:before {
    content: "3";

}


#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background: url(https://ik.imagekit.io/rzlzf40okya/elixir/gray_circle.svg);
    background-size: cover;
    margin: auto;
    color: #7E7E7E;
    z-index: 11;
    position: relative;

}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    /* background: rgba(0, 0, 0, 0.2); */
    position: absolute;
    left: 0;
    top: 21px;
    z-index: 1;
    border-top: 8px solid #EFF0F7;
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
}


#progressbar li.active:before,
#progressbar li.active:after {
    background-image: url(https://ik.imagekit.io/rzlzf40okya/elixir/red_circle.svg);
    border-color: #D0004B;
    color: #fff;
}

.congrats-screen {
    text-align: center;
}

/* select plan css starts */
.PlanCards {
    position: relative;
    overflow: hidden;
    padding: 30px 10px 10px 10px;
    background: #FFFFFF;
    border: 0.804144px solid #EFF0F7;
    box-shadow: 0px 2.37681px 16.6376px rgba(98, 106, 128, 0.16);
    border-radius: 9px;
    margin-bottom: 20px;
}

.PlanCards .customCheckbox {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 0px 9px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PlanCards .customCheckboxGrey {
    background: #E9E9E9;
}

.PlanCards .customCheckboxGreen {
    background: #00AC4F;
}

.PlanCards .planNo {
    font-weight: 700;
    font-size: 15px;
    color: #1A1A1A;
    text-decoration-line: none;
}

.PlanCards .planNo span {
    font-weight: 700;
    font-size: 15px;
    color: #80CBC4;
    text-transform: uppercase;
    text-decoration-line: none;
}

.PlanCards .Dashborder {
    border-top: 1px dashed #A0AEC0;
    margin: 10px 0;
}

.PlanCards .price {
    font-weight: 700;
    font-size: 28.5px;
    color: #1A1A1A;
}

.PlanCards .price del {
    font-weight: 600;
    font-size: 13.5px;
    color: #A0AEC0;
    margin-left: 10px;
}

.PlanCards .planBtn {
    border-radius: 150px;
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    height: 33px;
    width: 100%;
    border: none;
    margin: 10px 0;
}

.PlanCards .planBtnEnable {
    background: linear-gradient(107.91deg, #FF7AAA 7.37%, #D0004B 95.19%);
}

.PlanCards .planBtnDisable {
    background: #A0AEC0;
}

.PlanCards .planDetail {
    font-weight: 600;
    font-size: 13.5px;
    color: #1A1A1A;
}

.PlanCards ul {
    margin-bottom: 0px;
}

.PlanCards li {
    font-weight: 500;
    font-size: 12px;
    color: rgba(26, 26, 26, 0.67);
}

/* select plan css ends */
.customer_img {
    width: 100%;
    height: 166px;
    border-radius: 8px;
    object-fit: cover;
}

.mosque-bg .slick-slide {
    padding: 10px;
}

/* customer css */

.payment-footer {
    min-height: 680px;
}

.plan-footer {
    min-height: 680px;
}

.contact-footer {
    min-height: 687px;
}

.new-contact, contact-header {
    padding-top: 54px;
}

.new-contact .plan-header {
    padding-top: 54px;
}

.new-contact .payment-header {
    padding-top: 32px;
}

.mosque-bg {
    padding-bottom: 100px !important;
}

.mosque-bg h3 {
    margin: 20px 0px 30px;
}

.mosque-bg .slick-prev:before {
    content: url("https://ik.imagekit.io/rzlzf40okya/elixir/prev.svg")
}

.mosque-bg .slick-next:before {
    content: url("https://ik.imagekit.io/rzlzf40okya/elixir/next.svg");
}

.mosque-bg .slick-prev {
    left: -40px;
}

.customer_card {
    min-height: 400px;
}

.customer_card {
    background: #ffffff;
    border: 1px solid #eff0f7;
    border-radius: 12px;
    padding: 14px;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
}

.customer_card h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #1a1a1a;
    word-wrap: break-word;
}

.mobile-history {
    display: none;
}

.customer_card button {
    font-weight: 700;
    font-size: 14px;
    min-width: 100px;
    min-height: 32px;
}

.customer_card h4 {
    /* height: 100px; */
}

.auth_img {
    width: 36px !important;
    height: 36px !important;
    border-radius: 50%;
}

.contact-profile {
    margin-bottom: 90px;
}

.PlanCards .plan-li {
    margin-top: 7px;
    margin-bottom: 7px;
}

.mosque_blog_img {
    border-radius: 8px;
    height: 240.47px;
}

.label-missed-payments {
    color: #7E7E7E;
    margin-bottom: 5px;
}

.label-sent-msg {
    color: #7E7E7E;
}

.label-message {
    color: #7E7E7E;
    margin-right: 246px;
}

.label-msg {
    color: #1A1A1A;
    font-size: 14px;
}

/* .dotted-line {
    border-top: 1px dotted #000;
    margin-top: 10px;
  } */

.date-container {
    position: relative;
}

.vertical-dotted-line {
    position: absolute;
    top: 0;
    left: 25%;
    width: 1px;
    height: 100%;
    transform: translateX(-50%);
    border-left: 1px dotted #A0AEC0;
}

.fund-card-img-size {
    width: 300px;
    height: 200px;
    border-radius: 8px;
}

.mosque-blog-img {
    height: 28vw;
}

.payment-small-details {
    margin-bottom: 3px;
}

.payment-history-table {
    padding-top: 100px;
    margin-bottom: 250px;
}

.blog-card .h4-height {
    min-height: 84px;
}

.blog-detail-img-height {
    height: 30vw;
}

.member-info-table {
    height: 820px;
}

.congratulation-button {
    padding: 10px 20px;
    width: 170px;
    background-color: #D0004B;
    border: solid 1px #D0004B;
    color: white;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 800;
}

.mosque-unpaid-table {
    height: 870px;
}

.mosque-unpaid-small-table {
    height: 120px;
}

.contact-heading-five {
    padding-bottom: 20px;
}

.location_icon_spacing {
    padding-bottom: 14px;
}


/* Media Query starts */

@media only screen and (min-width: 993px) {
    .for-mobile {
        display: none;
    }
}

@media only screen and (max-width: 992px) {
    .for-desktop {
        display: none;
    }

    .banner-grid-for-mobile {
        display: flex;
        flex-direction: column;
        padding: 85px;
        text-align: center;
    }

    .banner-grid-for-mobile img {
        width: 100%;
        border-radius: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .feature-sec h4 {
        font-weight: 600;
        line-height: 11.864px;
        font-size: 16px;
    }

    .apart-box p {
        font-size: 14px;
        line-height: 21px;
    }

    .apart-box h5 {
        color: #1A1A1A;
        font-family: Raleway;
        font-size: 16px;
    }

    .feature-sec {
        background: #FAFAFA;
        padding: 2px 0px 0px 0px;
    }

    .row.mobile-row {
        flex-wrap: unset;
        overflow: scroll;
    }

    .about-grid {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 40px;
    }

    .payment-history-table {
        margin-bottom: 0px;
        padding-top: 22%;
        padding-bottom: 25%;
    }

    .customer_card h4 {
        font-size: 14px;
        line-height: 20px;
    }

    .payment-box {
        box-shadow: none;
        background-color: transparent;
    }

    .mobile-history .table-col {
        font-size: 10px;
        width: auto;
        padding: 10px 10px 10px 0px;
        min-height: 53px;
    }

    .table-row {
        display: grid;
        grid-template-columns: 5% 20% 40% 10% 10% !important;
        width: auto;
        clear: both;
    }

    .table_body {
        display: grid;
        grid-template-columns: 5% 20% 40% 10% 10% !important;
    }

    .mobile-history {
        display: block;
    }

    .desktop-history {
        display: none;
    }

    .customer_card {
        max-height: 385px;
    }

    ul {
        padding-left: 0rem;
    }

    .contact-form {
        display: flex;
        flex-direction: column;
    }

    .contact-box {
        margin: 0px 0px;
        padding: 30px 10px;
    }

    .board-box {
        width: 100%;
        padding: 30px;
    }

    .error-box {
        width: 100%;
    }

    .information-modal {
        width: 100%;
    }

    .banner-grid-for-mobile {
        padding: 50px 50px 0 50px;
        background-image: url("../../public/assets/mobileEXPORT.svg");
        height: 485px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100%;
    }

    .d-deskotop {
        display: none;
    }

    .d-mobile {
        display: block;
    }

    .apart-sec img {
        width: 100%;
    }

    .apart-right {
        margin-bottom: 25px;
        padding-right: 0px;
    }

    .first-track {
        margin: 2.5rem 0rem;
        display: flex;
        align-items: flex-start;
    }

    .first-track img {
        margin-right: 10px;
        width: 25px;
    }

    .secnd-track img {
        margin-right: 10px;
    }

    .third-track img {
        margin-right: 10px;
    }

    .fourth-track img {
        margin-right: 10px;
    }

    .fourth-track {
        display: flex;
        left: 0rem;
        align-items: flex-start
    }

    .secnd-track {
        top: 0rem;
        left: 0rem;
        display: flex;
        align-items: flex-start
    }

    .third-track {
        display: flex;
        align-items: flex-start
    }

    .third-track {
        margin-bottom: 1rem;
    }

    .left-track {
        text-align: left;
    }

    .tab_bg {
        padding: 0 20px;
    }

    .mobile-news-card {
        display: block;
    }

    .desktop-news-card {
        display: none;
    }

    .mosque-blog-img {
        height: 170px;
        object-fit: cover;
    }

    .new-contact {
        display: block;
    }

    .contact-box {
        max-width: 100%;
        min-width: 100%;
        border-radius: 20px 20px 0px 0px;
        box-shadow: 9.61924px 0px 25.6513px 0px rgba(0, 0, 0, 0.10);

    }

    .form-box {
        max-width: 320px;
        max-height: auto;
        padding: 20px 15px;
        border-radius: 0px 0px 20px 20px;
        background-color: #80CBC4;
        margin: 0 auto;
    }

    .form-box h4 {
        font-size: 16px;
    }

    .form-box p {
        font-size: 12px;
        line-height: 20px;
    }

    .about-us p {
        padding-bottom: 40px;
        color: #7E7E7E;
        line-height: 22px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 576px) {
    .about-grid {
        display: grid;
        grid-template-columns: 100%;
        gap: 40px;
    }

    .information-modal li {
        font-size: 16px;
    }

    .board-box {
        padding: 20px 10px;
    }

    .gray-bt,
    .prev-bt,
    .fill-bt {
        width: 100%;
        font-size: 12px;
        max-width: 150px;
        height: 44px;
    }

    .banner-content h2 {
        font-size: 20px;
    }

    .banner-content p {
        font-size: 12px;
        margin: 30px 0;
    }

    .onboarding-bg h2 {
        font-size: 20px;
        padding: 30px 0px;
    }

    #about-state {
        margin: 0 20px;
        width: auto;
        padding: 0;
    }

    .new-contact h2 {
        font-size: 20px;
        padding: 0px;
    }

    .mosque-bg .slick-prev {
        left: -8px !important;
        z-index: 99;
    }

    .mosque-bg .slick-next {
        right: 10px;
    }


}

/* Media Query ends */

